@import '~@reasoncorp/kyber-js/dist/scss/themes/misuite-v2/theme-misuite-v2';

.table-responsive {
  border: 1px solid #DDD;
  border-radius: 0.25rem;
  box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.1);

  th {
    background: #fafafa !important;
  }
}

.table-bordered tbody tr.selected {
  background-color: rgba(40, 128, 124, 0.1);
}